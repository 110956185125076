a {
  color: #00adef;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #0076a3;
  text-decoration: none;
}
#layout-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -75px;
}
#toolbar {
  z-index: 9999;
  background-color: #404042;
}
#toolbar ul.usernav li.dropdown a.dropdown-toggle,
#toolbar ul.usernav li.toplevel a {
  padding: 10px 8px;
  color: white;
  opacity: 0.8;
  text-decoration: none;
}
#page-head {
  background-color: white;
}
.navbar-header {
  /* float: none; */
}
.navbar-custom {
  border-top: none;
  border-bottom: 1px solid #404042;
}
.navbar-custom .nav > li > a {
  line-height: 55px;
}
.navbar-custom .nav li a,
.navbar-custom .nav li a:visited {
  color: #404042;
  text-transform: uppercase;
}
.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li a.dropdown-toggle:focus {
  color: black;
  background-color: #ededed;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu a,
.navbar-custom .nav li.dropdown ul.dropdown-menu a:visited {
  color: black;
  text-transform: none;
}
.navbar-custom .nav li.dropdown ul.dropdown-menu a:hover,
.navbar-custom .nav li.dropdown ul.dropdown-menu a:focus {
  color: #ededed;
  background-color: #404042;
}
.navbar-custom .nav .open > a,
.navbar-custom .nav .open > a:focus,
.navbar-custom .nav .open > a:hover {
  background-color: #ededed;
}
/* active */
.navbar-custom .nav li.active > a,
.navbar-custom .nav li.dropdown ul.dropdown-menu li.active a {
  color: #ffffff;
  text-decoration: none;
  background-color: #00adef;
  outline: 0;
  /* Disables the button completely. */
}
.navbar-custom .navbar-toggle {
  margin: 33px 30px 0 0;
  background-color: #404042;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 9999;
}
.navbar-custom .navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
}
.content img {
  display: block;
  max-width: 100%;
  height: auto;
}
#push,
#footer {
  height: auto;
  min-height: 75px;
  text-align: center;
}
#footer {
  background-color: #404042;
  border-top: 5px solid #404042;
  color: white;
}
#toTop {
  opacity: 0.7;
  color: #00adef;
}
.login-page {
  background-color: #404042;
}
.login-box {
  background-color: white;
  color: black;
  padding: 30px;
}
.login-box a,
.login-box a:visited {
  color: #00adef;
}
.login-box a:hover,
.login-box a:focus {
  color: #3386b9;
}
article.widget-leaders-bulletin-widget #tabs .thead-dark {
  background-color: #404042;
  color: #ededed;
}
.quicklinks-widget .panel-heading {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ddd;
}
.quicklinks-widget .panel-body ul li a {
  font-size: 24px;
}
.quicklinks-widget a span.speedpro {
  display: block;
  font-size: 14px;
  margin: 0 0 0 0px;
  vertical-align: middle;
}
.recent-news-widget h3 a {
  font-size: 24px;
}
.web-resources-widget .panel-heading {
  background-color: #404042;
  color: white;
}
.alert-danger {
  border: 3px solid #a94442;
}
.alert-danger h1 {
  font-size: 20px;
  font-weight: bold;
  color: #a94442;
  text-transform: uppercase;
}
a.danger-link:focus,
a.danger-link:hover {
  text-decoration: none;
  color: #c9302c;
}
a.danger-link {
  color: #d9534f;
  text-decoration: underline;
  text-transform: uppercase;
}
.alert-danger .img-responsive {
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .navbar-custom .nav > li:first-child {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  #toolbar {
    position: absolute;
    top: 30px;
    right: 90px;
    background-color: transparent;
  }
  #toolbar ul.usernav li.dropdown a.dropdown-toggle {
    color: #404042;
  }
  .navbar-custom .navbar-nav > li > a {
    line-height: normal;
  }
}
@media (max-width: 767px) {
  #toolbar {
    top: 20px;
  }
  #toolbar .dropdown-menu {
    position: fixed;
    top: 80px;
    /*height of main menu*/
    width: 100%;
  }
  .navbar-custom .navbar-toggle {
    margin: 24px 30px 0 0;
  }
}
