//
// PayMaster.WS override.less
// ---------------------------------------------------------------------------

//== Colors
//
//

@brand-primary: white; // light blue
@brand-secondary: #404042; // dark grey
@brand-tertiary: #00adef; // light blue

@color-dark: black;
@color-light: #ededed;

@login-page-bg: @brand-secondary;
@login-box-bg: @brand-primary;
@login-box-text-color: @color-dark;
@login-box-link-color: @brand-tertiary;
@login-box-link-hover-color: #3386b9;

@link-color: @brand-tertiary;
@link-color-hover: darken(@brand-tertiary, 15%);

@navbar-default-link-color: @brand-secondary;
@navbar-default-link-hover-color: @color-dark;
@navbar-default-link-hover-bg: @color-light;
@navbar-default-link-active-color: #ffffff;
@navbar-default-link-active-bg: @brand-tertiary;
@navbar-dropdown-link-color: @color-dark;
@navbar-dropdown-link-hover-color: @color-light;
@navbar-dropdown-link-hover-bg: @navbar-default-link-color;
@navbar-toggle-color: @brand-secondary;
@navbar-toggle-iconbar-color: #ffffff;

@footer-text-color: white;
@footer-bg: @brand-secondary;
@footer-border-color: @brand-secondary;


//== Layout
//
//
html, body {
}

a {
    color: @link-color;
    text-decoration: none;
}

a:hover, a:focus {
    color: @link-color-hover;
    text-decoration: none;
}

#layout-wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -75px;
}

// Toolbar
#toolbar {
    z-index: 9999;
    background-color: @brand-secondary;
}

#toolbar ul.usernav li.dropdown a.dropdown-toggle,
#toolbar ul.usernav li.toplevel a {
    padding: 10px 8px;
    color: white;
    opacity: 0.8;
    text-decoration: none;
}

// Header BG
#page-head {
    background-color: @brand-primary;
}

// Navbar

.navbar-header {
   /* float: none; */ // Break navbar to new row below logo
}

.navbar-collapse {

}

.navbar-custom {
    border-top: none;
    border-bottom: 1px solid @brand-secondary;
}

.navbar-custom .nav > li > a {
    line-height: 55px;  // Logo height
}

.navbar-custom .nav li a,
.navbar-custom .nav li a:visited {
    color: @navbar-default-link-color;
    text-transform: uppercase;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li a.dropdown-toggle:focus {
    color: @navbar-default-link-hover-color;
    background-color: @navbar-default-link-hover-bg;
}

.navbar-custom .nav li.dropdown ul.dropdown-menu a,
.navbar-custom .nav li.dropdown ul.dropdown-menu a:visited {
    color: @navbar-dropdown-link-color;
    text-transform: none;
}

.navbar-custom .nav li.dropdown ul.dropdown-menu a:hover,
.navbar-custom .nav li.dropdown ul.dropdown-menu a:focus {
    color: @navbar-dropdown-link-hover-color;
    background-color: @navbar-dropdown-link-hover-bg;
}

.navbar-custom .nav .open > a,
.navbar-custom .nav .open > a:focus,
.navbar-custom .nav .open > a:hover {
    background-color: @navbar-default-link-hover-bg;
}

/* active */
.navbar-custom .nav li.active > a,
.navbar-custom .nav li.dropdown ul.dropdown-menu li.active a {
    color: #ffffff;
    text-decoration: none;
    background-color: @brand-tertiary;
    outline: 0;
     /* Disables the button completely. */
}

.navbar-custom .navbar-toggle {
    margin: 33px 30px 0 0;
    background-color: @navbar-toggle-color;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    z-index: 9999;
}

.navbar-custom .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: @navbar-toggle-iconbar-color;
}

// Content
.content img {
    display: block;
    max-width: 100%;
    height: auto;
}

// Footer
#push, #footer {
    height: auto;
    min-height: 75px;
    text-align: center;
}

#footer {
    background-color: @footer-bg;
    border-top: 5px solid @footer-border-color;
    color: @footer-text-color;
}

#toTop {
    opacity: 0.7;
    color: @brand-tertiary;
}


// Login Page

.login-page {
    background-color: @login-page-bg;
}

.login-box {
    background-color: @login-box-bg;
    color: @login-box-text-color;
    padding: 30px;
}

.login-box a,
.login-box a:visited {
    color: @login-box-link-color;
}

.login-box a:hover,
.login-box a:focus {
    color: @login-box-link-hover-color;
}


// Leaders Bulletin
article.widget-leaders-bulletin-widget #tabs .thead-dark {
    background-color: @brand-secondary;
    color: @color-light;
}

// Quick Links
.quicklinks-widget .panel-heading {
    background-color: #f5f5f5;
    color: #333;
    border-color: #ddd
}

.quicklinks-widget .panel-body ul li a {
    font-size: 24px;
}

.quicklinks-widget a span.speedpro {
    display: block;
    font-size: 14px;
    margin: 0 0 0 0px;
    vertical-align: middle;
}

// Recent News
.recent-news-widget h3 a {
    font-size: 24px;
}

// Web Resources 
.web-resources-widget .panel-heading {
    background-color: @brand-secondary;
    color: white;
}

// Alerts
.alert-danger {
    border: 3px solid #a94442;
}

.alert-danger h1 {
    font-size: 20px;
    font-weight: bold;
    color: #a94442;
    text-transform: uppercase;
}

a.danger-link:focus,
a.danger-link:hover {
    text-decoration: none;
    color: #c9302c;
}

a.danger-link {
    color: #d9534f;
    text-decoration: underline;
    text-transform: uppercase;
}

.alert-danger .img-responsive {
    text-align: center;
    margin: 0 auto;
}

//==  Media Queries
//
//
@media (min-width: 1201px) {
  
}

@media (min-width: 992px) {
    .navbar-custom .container-fluid > .navbar-collapse {

    }

    
    .navbar-custom .nav > li:first-child {
        padding-left: 30px;
    }

}

@media (max-width: 991px) {

    #toolbar {
        position: absolute;
        top: 30px;
        right: 90px;
        background-color: transparent;
    }

    #toolbar ul.usernav li.dropdown a.dropdown-toggle {
        color: @brand-secondary;
    }

    .navbar-custom .navbar-nav > li > a {
        line-height: normal; // Logo height
    }

}

@media (max-width: 767px) {
    #toolbar { 
        top: 20px; 

    }

    #toolbar .dropdown-menu {
        position: fixed;
        top: 80px; /*height of main menu*/
        width: 100%
    }

    .navbar-custom .navbar-toggle {
        margin: 24px 30px 0 0;
    }

}

@media (max-width: 480px) {

}